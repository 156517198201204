import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetCustomersQueryVariables = {};

export type GetCustomersQuery = { __typename?: 'query_root' } & {
  customers: Array<
    { __typename?: 'customers' } & Pick<
      Types.Customers,
      'id' | 'name' | 'loan_fee'
    > & {
        delegates_aggregate: { __typename?: 'delegates_aggregate' } & {
          aggregate: Types.Maybe<
            { __typename?: 'delegates_aggregate_fields' } & Pick<
              Types.Delegates_Aggregate_Fields,
              'count'
            >
          >;
        };
      }
  >;
};

export const GetCustomersDocument = gql`
  query GetCustomers {
    customers(order_by: { name: asc }) {
      id
      name
      loan_fee
      delegates_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export function useGetCustomersQuery(
  options: Omit<Urql.UseQueryArgs<GetCustomersQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetCustomersQuery>({
    query: GetCustomersDocument,
    ...options,
  });
}

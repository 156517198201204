import * as Types from '../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type MarkMessagesAsReadMutationVariables = {
  objects: Array<Types.Messages_Read_Reciepts_Insert_Input>;
};

export type MarkMessagesAsReadMutation = { __typename?: 'mutation_root' } & {
  insert_messages_read_reciepts: Types.Maybe<
    { __typename?: 'messages_read_reciepts_mutation_response' } & Pick<
      Types.Messages_Read_Reciepts_Mutation_Response,
      'affected_rows'
    >
  >;
};

export const MarkMessagesAsReadDocument = gql`
  mutation MarkMessagesAsRead(
    $objects: [messages_read_reciepts_insert_input!]!
  ) {
    insert_messages_read_reciepts(objects: $objects) {
      affected_rows
    }
  }
`;

export function useMarkMessagesAsReadMutation() {
  return Urql.useMutation<
    MarkMessagesAsReadMutation,
    MarkMessagesAsReadMutationVariables
  >(MarkMessagesAsReadDocument);
}

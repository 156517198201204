import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type AddCustomerAddressMutationVariables = {
  object: Types.Addresses_Insert_Input;
};

export type AddCustomerAddressMutation = { __typename?: 'mutation_root' } & {
  insert_addresses_one: Types.Maybe<
    { __typename?: 'addresses' } & Pick<
      Types.Addresses,
      | 'id'
      | 'address1'
      | 'address2'
      | 'address3'
      | 'country'
      | 'postcode'
      | 'movein_date'
      | 'moveout_date'
    >
  >;
};

export const AddCustomerAddressDocument = gql`
  mutation AddCustomerAddress($object: addresses_insert_input!) {
    insert_addresses_one(object: $object) {
      id
      address1
      address2
      address3
      country
      postcode
      movein_date
      moveout_date
    }
  }
`;

export function useAddCustomerAddressMutation() {
  return Urql.useMutation<
    AddCustomerAddressMutation,
    AddCustomerAddressMutationVariables
  >(AddCustomerAddressDocument);
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetGroupsForBroadcastQueryVariables = {};

export type GetGroupsForBroadcastQuery = { __typename?: 'query_root' } & {
  groups: Array<{ __typename?: 'groups' } & Pick<Types.Groups, 'id'>>;
};

export const GetGroupsForBroadcastDocument = gql`
  query GetGroupsForBroadcast {
    groups(order_by: { id: asc }) {
      id
    }
  }
`;

export function useGetGroupsForBroadcastQuery(
  options: Omit<
    Urql.UseQueryArgs<GetGroupsForBroadcastQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetGroupsForBroadcastQuery>({
    query: GetGroupsForBroadcastDocument,
    ...options,
  });
}

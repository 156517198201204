import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeleteUserMutationVariables = {
  cognito_sub: Types.Scalars['String'];
};

export type DeleteUserMutation = { __typename?: 'mutation_root' } & {
  delete_users_by_pk: Types.Maybe<
    { __typename?: 'users' } & Pick<Types.Users, 'cognito_sub'>
  >;
};

export const DeleteUserDocument = gql`
  mutation DeleteUser($cognito_sub: String!) {
    delete_users_by_pk(cognito_sub: $cognito_sub) {
      cognito_sub
    }
  }
`;

export function useDeleteUserMutation() {
  return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
  );
}

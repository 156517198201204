import * as Types from '../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetUserMessagesQueryVariables = {
  userId: Types.Scalars['String'];
};

export type GetUserMessagesQuery = { __typename?: 'query_root' } & {
  messages: Array<
    { __typename?: 'messages' } & Pick<
      Types.Messages,
      'id' | 'type' | 'subject' | 'content' | 'is_read' | 'created_at'
    > & {
        recipients: Array<
          { __typename?: 'messages_recipients' } & {
            user: { __typename?: 'users' } & Pick<
              Types.Users,
              'cognito_sub' | 'email' | 'name'
            >;
          }
        >;
      }
  >;
  unread: { __typename?: 'messages_aggregate' } & {
    aggregate: Types.Maybe<
      { __typename?: 'messages_aggregate_fields' } & Pick<
        Types.Messages_Aggregate_Fields,
        'count'
      >
    >;
  };
};

export const GetUserMessagesDocument = gql`
  query GetUserMessages($userId: String!) {
    messages(
      where: {
        _and: {
          draft: { _eq: false }
          recipients: { user_cognito_sub: { _eq: $userId } }
        }
      }
      order_by: { created_at: desc, is_read: asc }
    ) {
      id
      type
      subject
      content
      is_read
      created_at
      recipients {
        user {
          cognito_sub
          email
          name
        }
      }
    }
    unread: messages_aggregate(where: { is_read: { _eq: false } }) {
      aggregate {
        count
      }
    }
  }
`;

export function useGetUserMessagesQuery(
  options: Omit<Urql.UseQueryArgs<GetUserMessagesQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetUserMessagesQuery>({
    query: GetUserMessagesDocument,
    ...options,
  });
}

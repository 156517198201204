import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetMessageByIdQueryVariables = {
  id: Types.Scalars['uuid'];
};

export type GetMessageByIdQuery = { __typename?: 'query_root' } & {
  message: Types.Maybe<
    { __typename?: 'messages' } & Pick<
      Types.Messages,
      'id' | 'created_at' | 'draft' | 'subject' | 'content' | 'is_read'
    > & {
        recipients: Array<
          { __typename?: 'messages_recipients' } & {
            user: { __typename?: 'users' } & Pick<
              Types.Users,
              'cognito_sub' | 'email' | 'name'
            >;
          }
        >;
        read_reciepts: Array<
          { __typename?: 'messages_read_reciepts' } & Pick<
            Types.Messages_Read_Reciepts,
            'type' | 'created_at'
          > & {
              user: { __typename?: 'users' } & Pick<
                Types.Users,
                'email' | 'name' | 'phone_number'
              > & {
                  user_devices: Array<
                    { __typename?: 'user_devices' } & Pick<
                      Types.User_Devices,
                      'manufacturer' | 'model'
                    >
                  >;
                };
            }
        >;
      }
  >;
};

export const GetMessageByIdDocument = gql`
  query GetMessageById($id: uuid!) {
    message: messages_by_pk(id: $id) {
      id
      created_at
      draft
      subject
      content
      recipients {
        user {
          cognito_sub
          email
          name
        }
      }
      read_reciepts {
        type
        user {
          email
          name
          phone_number
          user_devices {
            manufacturer
            model
          }
        }
        created_at
      }
      is_read
    }
  }
`;

export function useGetMessageByIdQuery(
  options: Omit<Urql.UseQueryArgs<GetMessageByIdQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetMessageByIdQuery>({
    query: GetMessageByIdDocument,
    ...options,
  });
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetUsersForMessageQueryVariables = {};

export type GetUsersForMessageQuery = { __typename?: 'query_root' } & {
  users: Array<
    { __typename?: 'users' } & Pick<
      Types.Users,
      'cognito_sub' | 'name' | 'email'
    >
  >;
};

export const GetUsersForMessageDocument = gql`
  query GetUsersForMessage {
    users(order_by: { email: asc }) {
      cognito_sub
      name
      email
    }
  }
`;

export function useGetUsersForMessageQuery(
  options: Omit<
    Urql.UseQueryArgs<GetUsersForMessageQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetUsersForMessageQuery>({
    query: GetUsersForMessageDocument,
    ...options,
  });
}

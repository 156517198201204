import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type RemoveUserPermissionsMutationVariables = {
  delegate_ids?: Types.Maybe<Array<Types.Scalars['uuid']>>;
};

export type RemoveUserPermissionsMutation = { __typename?: 'mutation_root' } & {
  delete_delegates: Types.Maybe<
    { __typename?: 'delegates_mutation_response' } & Pick<
      Types.Delegates_Mutation_Response,
      'affected_rows'
    > & {
        returning: Array<
          { __typename?: 'delegates' } & Pick<Types.Delegates, 'id'>
        >;
      }
  >;
};

export const RemoveUserPermissionsDocument = gql`
  mutation RemoveUserPermissions($delegate_ids: [uuid!]) {
    delete_delegates(where: { id: { _in: $delegate_ids } }) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export function useRemoveUserPermissionsMutation() {
  return Urql.useMutation<
    RemoveUserPermissionsMutation,
    RemoveUserPermissionsMutationVariables
  >(RemoveUserPermissionsDocument);
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetTemplatesQueryVariables = {};

export type GetTemplatesQuery = { __typename?: 'query_root' } & {
  message_templates: Array<
    { __typename?: 'message_templates' } & Pick<
      Types.Message_Templates,
      | 'id'
      | 'template_title'
      | 'template_body'
      | 'template_type_id'
      | 'is_active'
      | 'is_archived'
      | 'created_at'
      | 'updated_at'
    > & {
        message_template_type: { __typename?: 'message_template_types' } & Pick<
          Types.Message_Template_Types,
          'title'
        >;
      }
  >;
};

export const GetTemplatesDocument = gql`
  query GetTemplates {
    message_templates(
      where: { is_archived: { _eq: false } }
      order_by: { template_type_id: asc, template_title: asc }
    ) {
      id
      template_title
      template_body
      template_type_id
      message_template_type {
        title
      }
      is_active
      is_archived
      created_at
      updated_at
    }
  }
`;

export function useGetTemplatesQuery(
  options: Omit<Urql.UseQueryArgs<GetTemplatesQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetTemplatesQuery>({
    query: GetTemplatesDocument,
    ...options,
  });
}

import * as Types from '../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetNextLoanRefQueryVariables = {};

export type GetNextLoanRefQuery = { __typename?: 'query_root' } & {
  loans: Array<{ __typename?: 'loans' } & Pick<Types.Loans, 'reference'>>;
};

export const GetNextLoanRefDocument = gql`
  query GetNextLoanRef {
    loans(limit: 1, order_by: { reference: desc }) {
      reference
    }
  }
`;

export function useGetNextLoanRefQuery(
  options: Omit<Urql.UseQueryArgs<GetNextLoanRefQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetNextLoanRefQuery>({
    query: GetNextLoanRefDocument,
    ...options,
  });
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeletePaymentByIdMutationVariables = {
  id: Types.Scalars['uuid'];
};

export type DeletePaymentByIdMutation = { __typename?: 'mutation_root' } & {
  delete_payments_by_pk: Types.Maybe<
    { __typename?: 'payments' } & Pick<Types.Payments, 'id'>
  >;
};

export const DeletePaymentByIdDocument = gql`
  mutation DeletePaymentById($id: uuid!) {
    delete_payments_by_pk(id: $id) {
      id
    }
  }
`;

export function useDeletePaymentByIdMutation() {
  return Urql.useMutation<
    DeletePaymentByIdMutation,
    DeletePaymentByIdMutationVariables
  >(DeletePaymentByIdDocument);
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetMessageTemplateTypesQueryVariables = {};

export type GetMessageTemplateTypesQuery = { __typename?: 'query_root' } & {
  message_template_types: Array<
    { __typename?: 'message_template_types' } & Pick<
      Types.Message_Template_Types,
      'id' | 'title' | 'description'
    >
  >;
};

export const GetMessageTemplateTypesDocument = gql`
  query GetMessageTemplateTypes {
    message_template_types {
      id
      title
      description
    }
  }
`;

export function useGetMessageTemplateTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<GetMessageTemplateTypesQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetMessageTemplateTypesQuery>({
    query: GetMessageTemplateTypesDocument,
    ...options,
  });
}

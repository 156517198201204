import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeleteDeviceMutationVariables = {
  uuid: Types.Scalars['String'];
};

export type DeleteDeviceMutation = { __typename?: 'mutation_root' } & {
  delete_user_devices_by_pk: Types.Maybe<
    { __typename?: 'user_devices' } & Pick<Types.User_Devices, 'uuid'>
  >;
};

export const DeleteDeviceDocument = gql`
  mutation DeleteDevice($uuid: String!) {
    delete_user_devices_by_pk(uuid: $uuid) {
      uuid
    }
  }
`;

export function useDeleteDeviceMutation() {
  return Urql.useMutation<DeleteDeviceMutation, DeleteDeviceMutationVariables>(
    DeleteDeviceDocument,
  );
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertRateMutationVariables = {
  object: Types.Rate_Cards_Insert_Input;
};

export type UpsertRateMutation = { __typename?: 'mutation_root' } & {
  insert_rate_cards_one: Types.Maybe<
    { __typename?: 'rate_cards' } & Pick<
      Types.Rate_Cards,
      | 'id'
      | 'apr_lte_3000'
      | 'apr_gt_3000_lte_5000'
      | 'apr_gt_5000_lte_10000'
      | 'apr_gt_10000_lte_15000'
      | 'apr_gt_15000_lte_25000'
      | 'apr_gt_25000'
    >
  >;
};

export const UpsertRateDocument = gql`
  mutation UpsertRate($object: rate_cards_insert_input!) {
    insert_rate_cards_one(
      object: $object
      on_conflict: {
        constraint: rate_cards_pkey
        update_columns: [
          apr_lte_3000
          apr_gt_3000_lte_5000
          apr_gt_5000_lte_10000
          apr_gt_10000_lte_15000
          apr_gt_15000_lte_25000
          apr_gt_25000
        ]
      }
    ) {
      id
      apr_lte_3000
      apr_gt_3000_lte_5000
      apr_gt_5000_lte_10000
      apr_gt_10000_lte_15000
      apr_gt_15000_lte_25000
      apr_gt_25000
    }
  }
`;

export function useUpsertRateMutation() {
  return Urql.useMutation<UpsertRateMutation, UpsertRateMutationVariables>(
    UpsertRateDocument,
  );
}

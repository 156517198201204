import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ApproveLoanByIdMutationVariables = {
  id: Types.Scalars['uuid'];
};

export type ApproveLoanByIdMutation = { __typename?: 'mutation_root' } & {
  update_loans_by_pk: Types.Maybe<
    { __typename?: 'loans' } & Pick<Types.Loans, 'id'>
  >;
};

export const ApproveLoanByIdDocument = gql`
  mutation ApproveLoanById($id: uuid!) {
    update_loans_by_pk(_set: { is_approved: true }, pk_columns: { id: $id }) {
      id
    }
  }
`;

export function useApproveLoanByIdMutation() {
  return Urql.useMutation<
    ApproveLoanByIdMutation,
    ApproveLoanByIdMutationVariables
  >(ApproveLoanByIdDocument);
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type DeleteLoanByIdMutationVariables = {
  id: Types.Scalars['uuid'];
};

export type DeleteLoanByIdMutation = { __typename?: 'mutation_root' } & {
  delete_loans_by_pk: Types.Maybe<
    { __typename?: 'loans' } & Pick<Types.Loans, 'id'>
  >;
};

export const DeleteLoanByIdDocument = gql`
  mutation DeleteLoanById($id: uuid!) {
    delete_loans_by_pk(id: $id) {
      id
    }
  }
`;

export function useDeleteLoanByIdMutation() {
  return Urql.useMutation<
    DeleteLoanByIdMutation,
    DeleteLoanByIdMutationVariables
  >(DeleteLoanByIdDocument);
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ActiveMessageTemplateMutationVariables = {
  id: Types.Scalars['uuid'];
  templateTypeId: Types.Scalars['String'];
};

export type ActiveMessageTemplateMutation = { __typename?: 'mutation_root' } & {
  update_message_templates: Types.Maybe<
    { __typename?: 'message_templates_mutation_response' } & Pick<
      Types.Message_Templates_Mutation_Response,
      'affected_rows'
    >
  >;
  update_message_templates_by_pk: Types.Maybe<
    { __typename?: 'message_templates' } & Pick<Types.Message_Templates, 'id'>
  >;
};

export const ActiveMessageTemplateDocument = gql`
  mutation ActiveMessageTemplate($id: uuid!, $templateTypeId: String!) {
    update_message_templates(
      where: {
        _and: {
          is_active: { _eq: true }
          template_type_id: { _eq: $templateTypeId }
        }
      }
      _set: { is_active: false }
    ) {
      affected_rows
    }
    update_message_templates_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: true }
    ) {
      id
    }
  }
`;

export function useActiveMessageTemplateMutation() {
  return Urql.useMutation<
    ActiveMessageTemplateMutation,
    ActiveMessageTemplateMutationVariables
  >(ActiveMessageTemplateDocument);
}

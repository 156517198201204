import * as Types from '../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type NewDraftLoanMutationVariables = {
  object: Types.Loans_Insert_Input;
};

export type NewDraftLoanMutation = { __typename?: 'mutation_root' } & {
  insert_loans_one: Types.Maybe<
    { __typename?: 'loans' } & Pick<Types.Loans, 'id'>
  >;
};

export const NewDraftLoanDocument = gql`
  mutation NewDraftLoan($object: loans_insert_input!) {
    insert_loans_one(object: $object) {
      id
    }
  }
`;

export function useNewDraftLoanMutation() {
  return Urql.useMutation<NewDraftLoanMutation, NewDraftLoanMutationVariables>(
    NewDraftLoanDocument,
  );
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetRatesQueryVariables = {};

export type GetRatesQuery = { __typename?: 'query_root' } & {
  rate_cards: Array<
    { __typename?: 'rate_cards' } & Pick<
      Types.Rate_Cards,
      | 'id'
      | 'apr_gt_10000_lte_15000'
      | 'apr_gt_15000_lte_25000'
      | 'apr_gt_25000'
      | 'apr_gt_3000_lte_5000'
      | 'apr_gt_5000_lte_10000'
      | 'apr_lte_3000'
    >
  >;
};

export const GetRatesDocument = gql`
  query GetRates {
    rate_cards(order_by: { id: asc }) {
      id
      apr_gt_10000_lte_15000
      apr_gt_15000_lte_25000
      apr_gt_25000
      apr_gt_3000_lte_5000
      apr_gt_5000_lte_10000
      apr_lte_3000
    }
  }
`;

export function useGetRatesQuery(
  options: Omit<Urql.UseQueryArgs<GetRatesQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetRatesQuery>({ query: GetRatesDocument, ...options });
}

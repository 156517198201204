import * as Types from '../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetNewMessageCountQueryVariables = {
  userId: Types.Scalars['String'];
};

export type GetNewMessageCountQuery = { __typename?: 'query_root' } & {
  unread: { __typename?: 'messages_aggregate' } & {
    aggregate: Types.Maybe<
      { __typename?: 'messages_aggregate_fields' } & Pick<
        Types.Messages_Aggregate_Fields,
        'count'
      >
    >;
  };
};

export const GetNewMessageCountDocument = gql`
  query GetNewMessageCount($userId: String!) {
    unread: messages_aggregate(
      where: {
        recipients: { user_cognito_sub: { _eq: $userId } }
        draft: { _eq: false }
        is_read: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export function useGetNewMessageCountQuery(
  options: Omit<
    Urql.UseQueryArgs<GetNewMessageCountQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetNewMessageCountQuery>({
    query: GetNewMessageCountDocument,
    ...options,
  });
}

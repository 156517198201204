import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type InsertMessageTemplateMutationVariables = {
  object: Types.Message_Templates_Insert_Input;
};

export type InsertMessageTemplateMutation = { __typename?: 'mutation_root' } & {
  insert_message_templates_one: Types.Maybe<
    { __typename?: 'message_templates' } & Pick<
      Types.Message_Templates,
      | 'id'
      | 'is_active'
      | 'template_body'
      | 'template_title'
      | 'template_type_id'
    >
  >;
};

export const InsertMessageTemplateDocument = gql`
  mutation InsertMessageTemplate($object: message_templates_insert_input!) {
    insert_message_templates_one(object: $object) {
      id
      is_active
      template_body
      template_title
      template_type_id
    }
  }
`;

export function useInsertMessageTemplateMutation() {
  return Urql.useMutation<
    InsertMessageTemplateMutation,
    InsertMessageTemplateMutationVariables
  >(InsertMessageTemplateDocument);
}

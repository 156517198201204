import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type UpsertSettingsMutationVariables = {
  objects: Array<Types.Settings_Insert_Input>;
};

export type UpsertSettingsMutation = { __typename?: 'mutation_root' } & {
  insert_settings: Types.Maybe<
    { __typename?: 'settings_mutation_response' } & Pick<
      Types.Settings_Mutation_Response,
      'affected_rows'
    >
  >;
};

export const UpsertSettingsDocument = gql`
  mutation UpsertSettings($objects: [settings_insert_input!]!) {
    insert_settings(
      objects: $objects
      on_conflict: {
        constraint: settings_pkey
        update_columns: [description, value]
      }
    ) {
      affected_rows
    }
  }
`;

export function useUpsertSettingsMutation() {
  return Urql.useMutation<
    UpsertSettingsMutation,
    UpsertSettingsMutationVariables
  >(UpsertSettingsDocument);
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetUsersQueryVariables = {};

export type GetUsersQuery = { __typename?: 'query_root' } & {
  users: Array<
    { __typename?: 'users' } & Pick<
      Types.Users,
      'cognito_sub' | 'email' | 'name'
    > & {
        customers: Array<
          { __typename?: 'delegates' } & Pick<Types.Delegates, 'id'> & {
              customer: { __typename?: 'customers' } & Pick<
                Types.Customers,
                'id' | 'name'
              >;
            }
        >;
        customers_aggregate: { __typename?: 'delegates_aggregate' } & {
          aggregate: Types.Maybe<
            { __typename?: 'delegates_aggregate_fields' } & Pick<
              Types.Delegates_Aggregate_Fields,
              'count'
            >
          >;
        };
      }
  >;
};

export const GetUsersDocument = gql`
  query GetUsers {
    users(order_by: { email: asc }) {
      cognito_sub
      email
      name
      customers {
        id
        customer {
          id
          name
        }
      }
      customers_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export function useGetUsersQuery(
  options: Omit<Urql.UseQueryArgs<GetUsersQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetUsersQuery>({ query: GetUsersDocument, ...options });
}

import * as Types from '../../../../graphql/schemaTypes.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ArchiveMessageTemplateByIdMutationVariables = {
  id: Types.Scalars['uuid'];
};

export type ArchiveMessageTemplateByIdMutation = {
  __typename?: 'mutation_root';
} & {
  update_message_templates_by_pk: Types.Maybe<
    { __typename?: 'message_templates' } & Pick<Types.Message_Templates, 'id'>
  >;
};

export const ArchiveMessageTemplateByIdDocument = gql`
  mutation ArchiveMessageTemplateById($id: uuid!) {
    update_message_templates_by_pk(
      pk_columns: { id: $id }
      _set: { is_archived: true }
    ) {
      id
    }
  }
`;

export function useArchiveMessageTemplateByIdMutation() {
  return Urql.useMutation<
    ArchiveMessageTemplateByIdMutation,
    ArchiveMessageTemplateByIdMutationVariables
  >(ArchiveMessageTemplateByIdDocument);
}
